.container__about {
  width: 100%;
  height: 70%;
  margin: 0;
}
.background-container {
  background-image: linear-gradient(180deg, #24568e0a, #1b2e5005),
    url("../../images/Slides/Slide4.jpg");
  background-size: 100%, 100%;
  background-position: center;
  text-align: center;
  padding: 100px 0;
  position: relative;
  overflow: hidden;
  border-radius: 0.3%;
  object-fit: cover;
  background-repeat: no-repeat;

  /* Franja izquierda */
  &::before {
    content: "";
    position: absolute;
    width: 300px; /* Ancho de la franja */
    height: 100%;
    background: linear-gradient(
      180deg,
      #c046f4b1,
      #721f8b8f
    ); /* Colores de la franja */
    top: 0;
    left: 3%;
    transform: translateX(-110%) skew(-40deg, 0deg);
  }

    /* Franja derecha */
    &::after {
        content: '';
        position: absolute;
        width: 270px; /* Ancho de la franja */
        height: 100%;
        background: linear-gradient(180deg, #49eb49, #67f771ba); /* Colores de la franja */
        top: 0;
        right: 3%;
        transform: translateX(120%) skew(40deg, 0deg);
        opacity: 0.5;
    }
  }
  
  .content {
    padding: 50px 0px 55px; 
    border-radius: 5px;
    color: #fff;  
  }
  
  .content-h1 {
    font-size: 4vw;
    margin: 100px 0;
  }
  
  .content-p {
    font-size: 1.3vw;
    color: var(--color-nav-darker);
    text-align: center; /* Centramos el texto */
    padding: 15px; /* Agregamos espacio alrededor del texto para centrarlo */
    max-width: 550px; /* Limitamos el ancho del texto */
    margin: 100px auto; /* Centramos horizontalmente el texto */
    letter-spacing: 1px;
    font-weight: bold;
    background: #ffffff50;
  }
  
  .logo-animation {
    position: absolute;
    top: 70%; /* Ajusta la posición vertical según tu diseño */
    left: 40%; /* Ajusta la posición horizontal según tu diseño */
    transform: translate(-50%, -30%); /* Centra la imagen en el centro del contenedor */
    animation: moveLogo 5s linear infinite; 
    max-width: 17%;
  }

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; /* Agrega margen superior según tus preferencias */
}

@keyframes moveLogo {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9); /* Reducción de tamaño del 90% al 50% */
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 1010px) {
  
  .content-h1 {
    margin: 0;
  }
  
  .content {
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .background-container {
    padding: 0;
    top: -40px;
  }

  .content {
    padding-top: 135px;
  }

  .content-h1 {
    margin-top: 75px;
  }

  .content-p {
    font-size: 3vw;
  }
}
