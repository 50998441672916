/* ProyectoDetalle.css */

/* Estilos para el contenedor principal */
.proyecto-detalle-container {
    
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  /* Estilos para el título del proyecto */
  .proyecto-titulo {
    font-size: 28px;
    margin-bottom: 10px;
    color: rgb(204, 28, 28);
    padding-top: 74px;
  }

  .proyecto-empresa{
    color:#c832f1;
    font-size: 24px;
  }
  .proyecto-lugar{
    color:  #6ff095;
    font-size: 22px;
  }

  /* Estilos para los párrafos */
.proyecto-info p {
   
    margin-bottom: 8px;
    line-height: 1.4;
     /* Cambia el color según tus preferencias */
  }
  
  /* Estilos para la sección de imágenes */
  .imagenes-section {
    margin-top: 20px;

  }
  
  /* Estilos para las imágenes */
  .imagen-proyecto {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  
/* Estilos para el boton */
.button-regresar {
  padding: 10px 20px;
  font-size: 18px;
  background-color: #6ff095; /* Color del botón */
  color: #fff; /* Color del texto del botón */
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.button-regresar:hover{
  background-color: #c832f1;
}
.button-regresar a {
  text-decoration: none;
}