.card-about{
    
    margin: 10px 5% 10px 7% ;
   
}

.container-valores, .container-mision{
    background-color: var(--colorTextPastel);
    padding: 3% 0 3% 0;
 
}





.container-mision  svg{
    color: black ;
    margin-bottom: 10px;

}

.container-valores svg{
    color: white ;
}