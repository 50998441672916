/* Estilos generales para el contenedor de servicios */
.servicios-container {
  display: flex;
  justify-content: space-between;
  margin: 5rem 4%;
  flex-wrap: wrap; /* Permite que los servicios se ajusten a la pantalla */
  text-align: center;
}

/* Estilos para cada servicio */
.servicio {
  width: 60%; /* Ajusta el ancho para tres servicios en una fila */
  max-width: 400px; /* Establece un ancho máximo para cada servicio */
  height: 300px;
  perspective: 31905.3px;
  transition: transform 0.3s;
  position: relative;
  cursor: pointer;
  margin: 2rem 5px;
  overflow: hidden;
  border: .8rem ridge var(--colorTextDarker2);
  
}

.servicio img {
  width: 100%; /* Ajusta el ancho de la imagen al 100% del servicio */
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

/* Estilos para el estado normal (sin hover) */
.servicio .servicio-back {
  transform: rotateY(90deg);
  background-color: #ebebeb;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: transform 0.3s;
  -webkit-transition: transform 0.3s;
  -moz-transition: transform 0.3s;
  -ms-transition: transform 0.3s;
  -o-transition: transform 0.3s;
  -webkit-transform: rotateY(90deg);
  -moz-transform: rotateY(90deg);
  -ms-transform: rotateY(90deg);
  -o-transform: rotateY(90deg);
}

/* Estilos para el estado hover */
.servicio:hover {
  transform: rotateY(0);
  -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0);
  -ms-transform: rotateY(0);
  -o-transform: rotateY(0);
}

.servicio:hover .servicio-back {
  transform: rotateY(0);
  -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0);
  -ms-transform: rotateY(0);
  -o-transform: rotateY(0);
}

/* Estilos para los títulos y descripciones */
.servicio h2 {
  text-align: center;
  color: #000; /* Cambia el color del título */
}

.servicio p {
  text-align: center;
  color: #000; /* Cambia el color del texto */
  
}

@media (max-width: 768px) {
  .servicios-container {
    flex-direction: column;
    align-items: center;
   
  }

  .servicio {
    width: 100%;
    max-width: 50rem;
    margin-bottom: 4rem;
    height: auto;
  }
  .servicio .servicio-back {
    animation: rotateAnimation 10s linear infinite;
    animation-delay: 2s;
    -webkit-animation: rotateAnimation 10s linear infinite;
}
    .rotate-text {
    transform: none;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
}

 
}

.servicio.stop-animation .servicio-back {
  animation-play-state: paused;
}

/* Define la animación de rotación continua */
@keyframes rotateAnimation {
  0% {
    transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
}
  50%{
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}
  100% {
    transform: rotateY(360deg);
    -webkit-transform: rotateY(360deg);
    -moz-transform: rotateY(360deg);
    -ms-transform: rotateY(360deg);
    -o-transform: rotateY(360deg);
}
}


@media (max-width: 980px){
  .servicios-container {
    
    justify-content: space-around;
   
  }
}