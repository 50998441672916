.nuestros-proyectos {
    text-align: center;
    padding: 20px;
  }
  
  .proyectos-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
    
  }
  
  .proyecto {
    width: 420px;
    height: 300px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    transition: transform 0.2s, border-color 0.3s;
    position: relative; /* Agrega una posición relativa al proyecto */
    overflow: hidden; /* Oculta el contenido que se desborda */
    margin-bottom: 3.5rem;
    cursor: pointer;
  }
  
  .proyecto img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .info-proyecto {
    position: absolute; /* Posición absoluta para la información */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    color: rgb(253, 253, 253);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s;
  }
  .proyecto:hover {
    transform: scale(1.05); /* Efecto de escala al pasar el cursor */
    border-color: #6ff095; /* Cambio de color del borde al pasar el cursor */
  }
  
  .proyecto:hover .info-proyecto {
    opacity: 1; /* Muestra la información al pasar el cursor */
  }
  
 
  .proyecto-lugar {
    color: bisque;
    text-align: center;
  }
  
  .proyecto-empresa {
    color: blanchedalmond;
  }
  .proyecto-proyecto{
    color:antiquewhite;
    text-align: center;
  }

  /*-------------------------------------------------*/
  
  .proyecto-titulo{
    font-size: 1.5rem;
    position: absolute;
    top:  25.5rem;
    left: -0.1rem;
    background-color: var(--colorTextDarker2);
    width: 101%;
    height: 14%;
    margin: 5px 0;
    padding:5px;
    box-sizing: border-box;
  }

  .proyecto-empresa{
    font-size: 1.5rem;
    position: absolute;
    bottom: 1rem;
    right: 2rem; 
    padding: 0 0 0 .7rem;
  }


  
  .proyecto-proyecto, .proyecto-lugar{
    font-size: 1.3rem;
    padding: .3rem 2rem 0 2rem;
  
   
  }

  .proyecto-proyecto{
    padding-top: 3rem;
   }