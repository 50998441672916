/* Modal Container */

/* Modal Content */
.modal-content {
  background-color: rgba(255, 255, 255, 0);
  z-index: 1;
  border-radius: 5%;
  display: block;
  position: absolute;
  width: 95%;
  border: none;
}

.close {
  margin-bottom: 0.8rem;
  left: 24.5rem;
  position: relative;
  z-index: 2;
  width: 3.2rem;
  height: 3.2rem;
  background-color: #25d366;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
}

.modal-section-ventas {
  background-color: #25d366;
  border-radius: 1rem 1rem 0 0;
  padding: 2.5rem 0 1.7rem 0;
  text-align: center;
}

.modal-section-ventas p {
  padding-top: 0.5rem;
}

.modal-section-linea {
  background-color: #fff;
  border-radius: 0 0 1rem 1rem;
  text-align: center;
  height: 6rem;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  color: #888fbb;
}

.img-modal {
  width: 5.5rem;
  background-color: var(--color-nav-darker);
  border-radius: 50%;
}

.modal-section-message {
  background-color: #fff;
  justify-content: space-between;
  padding: 0 .5rem 0 1rem;
  display: flex;
  text-align: center;
  align-items: center;
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.send-button {
  background-color: rgba(0, 0, 0, 0) !important;
  border: none;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
}

.enviar {
  width: 100%;
  height: 100%;
  max-width: 3.5rem;
  max-height: 4rem;
  cursor: pointer;
  display: flex;
  margin: 0 0.5rem;
}

.message-box {
  margin: 0;
  height: 5rem;
  border: none;
  border-radius: 1rem;

  background-color: #fff;
}

/* Modal Container */

.disable {
  animation: Desaparecer 3s ease;
  position: fixed;
}

.active {
  position: fixed;
  animation: Aparecer 1s ease;
}

@keyframes Aparecer {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes Desaparecer {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(140%);
  }
}

/* Agrega las transiciones para la animación */
.modal {
  height: 30rem;
  width: 29rem;
  right: 0rem;

  z-index: 10;
  display: block;
  transition: transform 1s ease; /* Añade la propiedad de transición */
  transform: translateY(140%); /* Inicia con el modal fuera de la vista */
  position: absolute;
}

.modal-container {
  width: 29rem;
  right: 0rem;

  bottom: 6.6rem;
  z-index: 10;
  display: block;

  overflow: hidden;
  position: fixed;
}

.modal.active {
  transform: translateY(
    0
  ); /* Muestra el modal al cambiar la clase a "active" */
}

.modal.disable {
  transform: translateY(
    140%
  ); /* Oculta el modal al cambiar la clase a "disable" */
}
