*,
*::after,
*::before {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
}

:root {
  --color-nav: #a785c7;
  --colorText: #62e368;
  --colorText1: #8abf7a;
  --colorBlack: #202020be;
  --color-nav-darker: hsl(260, 60%, 40%);
  --color-nav-darker1: hsl(260, 38%, 50%);
  --colorTextDarker: hsl(120, 40%, 50%);
  --colorTextDarker2: hsl(120, 80%, 75%);
  --colorPruebaNav: #1bae70;
  --colorPruebaContraste: #a01b70;
  --color-text-default: #350067;
}

html {
  font-size: 62.5%;
}

@font-face {
  font-family: "Fuente1";
  src: url("../src/images/Fonts/BebasNeue-Regular.ttf") format("woff2"),
    url("../src/images/Fonts/BebasNeue-Regular.ttf") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Fuente2";
  src: url("../src/images/Fonts/BrunoAceSC-Regular.ttf") format("woff2"),
    url("../src/images/Fonts/BrunoAceSC-Regular.ttf") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Fuente3";
  src: url("../src/images/Fonts/Rajdhani-Medium.ttf") format("woff2"),
    url("../src/images/Fonts/Rajdhani-Medium.ttf") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Fuent4";
  src: url("../src/images/Fonts/RobotoMono-VariableFont_wght.ttf")
      format("woff2"),
    url("../src/images/Fonts/RobotoMono-VariableFont_wght.ttf") format("woff");
  font-weight: normal;
  font-style: normal;
}

.text-slide__info,
.count-title,
.count-number,
.count-description {
  font-family: "Fuente1", sans-serif;
}

.text-info,
.text-slide__buttons a {
  font-family: "Fuente2", sans-serif;
}

.nav,
.cards p,
.card-1 p,
.card .text-info,
.sb__footer-links-div h4,
.sb__footer-links-div p,
.sb__footer-copyright,
.whatsapp-button,
.modal-section-ventas {
  font-family: "Fuente3", sans-serif;
}

.text-title, 
.card-text-title-info {
  font-family: "Fuente4", sans-serif;
  font-weight: bold;
}
.h3-nombre-prod {
  font-size: 1.7vw;
  color: #000;
  font-family: "Fuente3", sans-serif;
  font-weight: bolder;
  letter-spacing: 1px;
}

.title-categoria{
  color: red;
}



.cards p,
.card-1 p {
  font-size: 20px;
}

.count-title {
  font-size: 50px;
  letter-spacing: 2px;
}

.count-number {
  letter-spacing: 10px;
}

.count-description {
  font-size: 25px;
}

.count-title,
.count-number,
.count-description {
  color: #fff;
}

.text-slide__buttons a {
  font-weight: 500;
}

.text-slide__info {
  font-size: larger;
  font-weight: 1200;
}

.modal-section-ventas,
.close {
  font-weight: bold;
}

.close {
  font-weight: 900;
}

.message-box:focus {
  outline: none;
}

body {
  background: rgb(255, 255, 255);
  color: var(--color-text-default);
}
@media screen and (min-width: 1500px) and (max-width: 1920px) {
  .nav__letter {
    margin: 0 4rem;
    display: flex;
    height: 100%;
    align-items: center;
    font-size: 1.5vw;
    letter-spacing: 1rem;
  }
}
.dropdown-content {
  letter-spacing: 0;
}

.content-p {
  font-family: "Fuente3", sans-serif;
}
