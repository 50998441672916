.whatsapp-button {
    position: fixed;
    bottom: 12px;
    right: 17px;
    background-color: #25d366;
    color: #fff;
    border-radius: 25px;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s ease-in-out;
    font-size: 20px;
    width: 180px;
    font-family:  Yantramanav;
    z-index: 1000;
  }
  
  
 
  .whatsapp-button:hover{
    background-color: #128c7e;
  }



