.container__count{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width:100%;
    text-align: left;
    margin-bottom: 2rem;
    background: url("../../images/fondotest.jpg");
    background-size: cover;
    filter: blur(.5px);
   
}

.container__item{
   width: 240px;
   justify-content: center;
   align-items: center;
   text-align: center;
  margin: 5rem auto;
}


.count-title{
    font-size: 30px;
    /*line-height: 10px;*/
    font-weight: 600;
   
   
}
.count-number{
    font-size: 80px;
    line-height: .5em;
    font-weight: 600;
    margin:  3.5rem 0 4rem 0;
    color: #fff
    
}
.count-description{
    font-size: 18px;
   
}




@media screen and (max-width: 550px) {
    .container__count{
       flex-wrap: nowrap;
      justify-content: center;
      flex-direction: column;
      
    }

    .container__item{
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-bottom: 5rem;
         
     }
}


