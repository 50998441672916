.nav li {
  list-style: none;
}

.nav a {
  text-decoration: none;
  color: #fff;
  margin-left: 2rem;
  position: relative; /* Añadido para posicionar el submenú absoluto */
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  height: 15vh;
  background: #c7e04e;
  padding: 0 2rem;
  font-size: 1.5vw;
  font-weight: 400;
  /*position: fixed;*/
  position: relative;
  z-index: 80;
}

.logo-nav {
  width: 31%;
  height: 32%;
  display: block;
}

.nav > .nav__brand {
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
}

.nav__menu {
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  
}

.nav__item {
  position: relative; /* Añadido para posicionar el submenú absoluto */
}
.nav a {
  text-decoration: none;
  color: #fff;
  margin-left: 2rem;
  position: relative;
}

.nav__toggler div {
  width: 2.5rem;
  height: 0.2rem;
  margin: 0.4rem;
  background-color: #fff;
  
  transition: .4s ease-in;
  -webkit-transition: .4s ease-in;
  -moz-transition: .4s ease-in;
  -ms-transition: .4s ease-in;
  -o-transition: .4s ease-in;
}

.nav__toggler {
  cursor: pointer;
  display: none;
}

.dropdown {
  position: relative;
}

.dropdown span {
  display: inline-block;
  padding: 0.5rem 1rem; /* Ajusta el espaciado según tus preferencias */
  margin: 0.2rem; /* Ajusta el margen según tus preferencias */
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #7375a5;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown:hover .dropdown-content {
  display: block;
}

.categoria {
  padding: 1rem;
  border-bottom: 1px solid #fff;
  position: relative;
  margin-bottom: 5px;
}

.categoria span {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin: 0.2rem;
  cursor: pointer;
  font-size: 1.2vw;
}

.categoria strong {
  color: #fff;
  font-size: 1.2vw;
}

.categoria ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.categoria ul li {
  margin: 0;
  padding: 0.5rem 0;
}

.categoria a {
  color: #fff;
  text-decoration: none;
  font-size: 1.5vw;
}

.tipo-size {
  font-size: 1vw !important;
}

@media screen and (max-width: 1600px) {
  .dropdown-content {
    left: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .nav__toggler {
    display: block;
    cursor: pointer;
  }
  .nav__menu {
    position: fixed;
    top: 12vh;
    right: 0;
    width: 100%;

    background: #7375a5;
    flex-direction: column;
    transform: translateY(-170%);
    transition: 0.5s ease-in;
    z-index: 100;
    align-items: flex-start;

    padding-left: 2rem;
  }

  nav a:nth-child(1) {
    font-size: 20px;
  }
  .nav {
    position: fixed;
    width: 100%;
  }
  .awssld {
    padding-top: 12.5rem;
  }
  .dropdown {
    width: 15rem;
  }

  .dropdown-content {
    left: 12rem;
    top: -40px;
  }

  .tipo-size {
    font-size: 3vw !important;
  }
}

/*Clase nav active*/
.nav__active {
  transform: translate(0);
}

.toggle .line1 {
  transform: rotate(-45deg) translate(-4px, 5px);
}

.toggle .line2 {
  opacity: 0;
}

.toggle .line3 {
  transform: rotate(45deg) translate(-4px, -5px);
}

/* Agrega estilos para las categorías y subcategorías */
