.container-client {
  display: grid;
  place-items: center;
  gap: 5rem;
  padding-top: 5rem;
  background-color: rgb(255, 255, 255);

}



.slider {
  height: 150px;
  margin: auto;
  position: relative;
  width: 90%;
  display: grid;
  place-items: center;
  overflow: hidden;
  margin-bottom: 2rem;
}

.slide-track {
  display: flex;
  width: calc(250px * 18);
  animation: scroll 40s linear infinite;
}

.slide-track:hover {
  animation-play-state: paused;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 9));

  }
}

.slide {
  height: 200px;
  width: 250px;
  display: flex;
  align-items: center;
  padding: 15px;
  perspective: 100px;
}

img {
  width: 100%;
  transition: transform 1%;
}

img:hover {
  transform: translateZ(20px);

}

/* EFECTO DE SOMBRAS */
.slider::before,
.slider::after {
  background: linear-gradient(to right, rgba(255, 255, 255, 0.137)0%,
      rgba(255, 255, 255, 0)100%);
  content: '';
  height: 100%;
  position: absolute;
  width: 15%;
  z-index: 2;
}

.slider-before {
  left: 0;
  top: 0;
}

.slider-after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}