.subcategorias {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 150px; /* Ajusta el ancho según tus necesidades */
  background-color: #7375a5;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.subcategorias li {
  position: relative;
}

.subcategorias a {
  display: block;
  padding: 0.75rem;
  color: #fff;
  text-decoration: none;
  transition: background-color 0.3s;
  font-size: 1vw;
}

.cat-w {
  width: 12vw;
}

@media screen and (max-width: 1600px) {
  .subcategorias {
   
    width: 11vw !important;
  }

  .categoria ul {
    width: 14vw;
  }
}

@media screen and (max-width: 1280px) {
  .subcategorias {
    left: -100% !important;
    width: 12vw !important;
  }

  .categoria {
    width: 12vw;
  }
}

@media screen and (max-width: 1180px) {
  .subcategorias {
    left: -115% !important;
  }

  .categoria {
    width: 11.5vw;
  }
}

@media screen and (max-width: 1024px) {
  .subcategorias {
    left: -125% !important;
    width: 10vw !important;
  }

  .categoria {
    padding-left: 1.5rem;
  }
}

@media screen and (max-width: 920px) {
  .subcategorias {
    left: -135% !important;
  }
}

@media screen and (max-width: 850px) {
  .subcategorias {
    left: -150% !important;
  }

  .categoria ul {
    width: 11vw !important;
  }
}

@media screen and (max-width: 768px) {
  .subcategorias {
    left: 100% !important;
  }

  .categoria {
    width: 30vw !important;
  }
}
