.nuestra-historia-card {
  display: flex;
  align-items: center;
  margin: 50px 20px; /* Espacio entre las tarjetas */
  /* border: 1px solid #7173f0; /* Borde de 1 píxel sólido en un color gris claro */
  /* box-shadow: 0 2px 4px rgba(202, 119, 238, 0.1); /* Sombreado ligero */
  padding: 25px; /* Espacio interior alrededor del contenido */
  box-sizing: border-box;
}
.titulo-historia {
  text-align: center;
}

.nuestra-historia-image {
  flex: 2; /* Ocupa una fracción del espacio disponible */
  text-align: center;
  margin-top: 10%;
}

.nuestra-historia-image img {
  max-width: 75%;
  height: auto;
  border-radius: 5%;
}

.nuestra-historia-text {
  flex: 2; /* Ocupa dos fracciones del espacio disponible */
  text-align: justify;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
} 

@media (max-width: 768px) {
  .nuestra-historia-card {
    flex-direction: column; /* Cambia a diseño de columna */
    align-items: center;
  }

  .nuestra-historia-image {
    margin-left: 0; /* Elimina el espacio entre el texto y la imagen en pantallas pequeñas */
    margin-top: 20px; /* Agrega espacio arriba de la imagen */
    text-align: center;
  }
  .rotated-container , .contenedor-imagen{
    max-height: 400px !important;
    transform: none !important;
    margin: 0 !important;
    width: 80vw !important;
  }
 
}

/* Card-NUESTRO PRODUCTOS ESTÁ EN EL HOME */
.card-product-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white; /* Color de fondo */
  padding: 50px;
}

.card-product-content {
  display: flex;
  align-items: center;
  max-width: 1000px;
  width: 100%;
}

.card-product-image img {
  max-width: 500px;
  height: 350px;
}

.card-product-text {
  flex: 1;
  max-width: 600px;
  padding: 10px;
  margin-right: 10px;
}

.card-product-text h1 {
  font-size: 33px;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
}

.card-product-text p {
  font-size: 20px;
  line-height: 1.5;
  max-width: 100%;
  text-align: center;
}

.card-product-button {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.card-product-button button {
  padding: 10px 20px;
  font-size: 18px;
  background-color: #6ff095; /* Color del botón */
  color: #fff; /* Color del texto del botón */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.card-product-button button:hover {
  background-color: #c832f1; /* Color de fondo al pasar el ratón */
}



.contenedor-imagen {
  flex: 2; /* Ocupa una fracción del espacio disponible */
  text-align: center;
  overflow: hidden;
 
  background-color: #6ff095;
  margin: 7rem 10rem;
}

.contenedor-imagen .rotated-container {
  width: 40vw;
 max-height:30vw ;
 
}

.imagen-prod {
  width: 50vw;
  height: 100%;
  object-fit: cover; /* Ajusta la imagen al tamaño del contenedor */
}
