.valores-list {
    list-style: none;
    padding: 0;
    display: flex; /* Establece la lista como un flex container */
    justify-content: space-around; /* Distribuye los elementos equitativamente */
  }
  
  .valor-item {
    text-align: center;
  }
  
  .valor-icon {
    font-size: 54px; /* Tamaño del ícono */
    margin-bottom: 10px; /* Espacio entre el ícono y el texto */
  }
  
  .texto-valores {
    font-size: 15px; /* Tamaño del texto */
    text-align: justify center;
    margin-top: 10px;
    line-height: 1.4;
    
  }

  .titulo-valores{
    font-size: calc(10px + 1.3vim );
    font-weight: 700;
    margin-bottom: calc(10px + 1.5vim);
  }



