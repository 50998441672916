:root {
    --color-nav: #A785C7;
    --colorText: #8ABF7A;
    --colorBlack: #000;
    --color-nav-darker: hsl(260, 38%, 50%);
    --colorTextDarker: hsl(120, 40%, 50%);
    --colorTextPastel: hsl(120, 40%, 80%);
   

}

.container-info {

    margin: 7rem 3rem;
    padding: 0px 20px;
    flex-direction: row;
    align-items: center;
    display: flex;
    
}



section {

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.card-text-title-info {
    font-size: 2rem;
    line-height: 1.2;
    height: 4rem;
    color: var(--color-nav-darker);
}

.text-info {
    color: var(--colorTextDarker);
}

.text-title {
    color: var(--color-nav-darker);
    font-size: 3em;
    font-weight: 400;

}



.cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
   

}

.card {
    flex: 0 0 calc(50% - 20px);
    max-width: calc(50% - 20px);
    width: 100%;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
   
}

.valores {
    flex: 0 0 calc(30% - 20px);
    max-width: calc(30% - 20px);
    width: 100%;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
}

.card-1 {
    width: 100%;
    max-width: none;
    border-radius: 10px;
    padding: 20px;
    margin: 2rem 7rem 0 11rem;
    height: 60vh;
}

.icono {
    width: 8rem;
    height: 8rem;
    padding: 2rem;
    color: var(--colorText);
}

.subcontainer-1 {

    margin-right: 8%;
}


@media screen and (max-width: 550px) {
    section {
        padding: 20px 0px;
    }

    .card-text-title-info {
        font-size: 30px;
        line-height: 1.2;

    }

    .card,
    .valores {
        flex: 0 0 100%;
        max-width: none;
    }

    .container-info {
        flex-direction: column;
        max-width: 90%;
        align-items: center;
        /* Centrar elementos en dispositivos pequeños */
    }

    .subcontainer-2 {
        width: 100%;
        /* Ocupar el ancho completo */
    }

    .text-title {
        color: var(--color-nav-darker);
        font-size: 200%;
        font-weight: 400;
    }

    .card-text-title-info {
        font-size: 100%;
    }

}




@media screen and (max-width: 900px) {
    .card-1 {
        width: 100%;
        /* Ancho completo */
        max-width: none;
        border-radius: 10px;
        padding: 1rem;
        margin: 1rem auto;
        /* Margin ajustado para centrar verticalmente */
        height: auto;
    }
}







@keyframes beat {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  

.bounce {
    animation: bounce 1.6s ease infinite;
}

.beat {
    animation: beat 1.4s ease infinite;
}

/* Clase para la animación "fade" */
.fade {
    animation: fade 1.4s ease infinite;
}